import { getHeaders } from "../utils/authHeaders";
import React, { Fragment, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Box,
  CircularProgress,
  Toolbar,
  Switch,
  Tooltip,
  Collapse,
  Button,
} from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SaveIcon from "@mui/icons-material/Save";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { styled } from "@mui/system";
import { Person } from "../models/person";
import { useForm, Controller, set } from "react-hook-form";
import axios from "axios";
import { DeleteDialog } from "./DeleteDialog";

interface ComparedTableProps {
  primaryId: string;
  secondaryId: string;
  dataType: string;
}

const HeaderCell = styled(TableCell)`
  width: 200px;
  font-weight: bold;
`;

const ValueCell = styled(TableCell)`
  width: auto;
`;

function capFirst(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const renderValue = (value: any, handleChange: (newValue: any) => void) => {
  return <Typography> {JSON.stringify(value)} </Typography>;
};

function listNonClassProperties(data: any): string[] {
  const nonClassProperties: string[] = [];
  const skipProperties = [
    "inverseChoreographer1",
    "inverseChoreographer2",
    "inverseHeadCoach",
    "dataType",
  ];
  for (const key of Object.keys(data)) {
    if (
      !(
        data[key] instanceof Object &&
        data[key].constructor.name !== "Array"
      ) &&
      !skipProperties.includes(key)
    ) {
      nonClassProperties.push(key);
    }
  }
  return nonClassProperties;
}

const ComparedTable: React.FC<ComparedTableProps> = ({
  primaryId,
  secondaryId,
  dataType,
}) => {
  const [primary, setPrimary] = useState<any>();
  const [secondary, setSecondary] = useState<any>();
  const [newPrimary, setNewPrimary] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [nonClassProp, setNonClassProp] = useState<string[]>([]);
  const [expandedRow, setExpandedRow] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [showAllField, setShowAll] = useState<boolean>(false);
  const [showOnlyDiff, setShowOnlyDiff] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Person>({
    defaultValues: newPrimary,
  });

  useEffect(() => {
    const getPerson = async () => {
      // const response = await fetch(`api/Person/${primaryId}, ${secondaryId}`);
      // const data = await response.json();
      setLoading(true);
      const ids = `${primaryId},${secondaryId}`;
      const response = await axios.get<Person[]>(
        `/api/Person/list/${encodeURIComponent(ids)}`,
        await getHeaders()
      );
      if (response.data.length !== 2) {
        console.error(`Expected 2 people, but got ${response.data.length}`);
        return;
      }
      const data = [response.data[0], response.data[1]];
      reset(data[0], { keepDirty: true });
      setNewPrimary(data[0]);
      setPrimary(data[0]);
      setSecondary(data[1]);
      setNonClassProp(
        Column(data[0], showAllField).filter((p) => p !== "id")
        // .sort((a, b) => a[0].localeCompare(b[0]))
      );

      setLoading(false);
    };

    getPerson();
  }, []);

  useEffect(() => {
    if (showOnlyDiff) {
        setNonClassProp(
          Column(primary, false)
            .filter((p) => p !== "id")
            .filter((p) => primary[p] !== secondary[p])
          // .sort((a, b) => a[0].localeCompare(b[0]))
        );
      } else {
        setNonClassProp(
          Column(primary, showAllField).filter((p) => p !== "id")
          // .sort((a, b) => a[0].localeCompare(b[0]))
        );
      }
  },[showAllField, showOnlyDiff])

  const Column = (person: Person, allField: boolean): string[] => {
    if (!allField) {
      return [
        "membershipNumber",
        "firstName",
        "middleName",
        "lastName",
        "suffix",
        "birthdate",
        "ageVerified",
        "gender",
        "primaryEmail",
        "alternateEmail",
        "homePhone",
        "workPhone",
        "primaryAddress",
        "alternateAddress",
        "status",
        "uscitizen",
        "eligibilityType",
        "released",
        "citizenshipForm",
        "foreignFederation",
        "password",
        "notes",
      ];
    } else {
      return [
        "membershipNumber",
        "firstName",
        "middleName",
        "lastName",
        "suffix",
        "birthdate",
        "ageVerified",
        "gender",
        "primaryEmail",
        "alternateEmail",
        "homePhone",
        "workPhone",
        "primaryAddress",
        "alternateAddress",
        "status",
        "uscitizen",
        "eligibilityType",
        "released",
        "citizenshipForm",
        "foreignFederation",
        "password",
        "notes",
        "alternateAddressId",
        "alternateEmailId",
        "athleteAlumni",
        "changePassword",
        "createdById",
        "createdDate",
        "choreographer1Id",
        "choreographer2Id",
        "complianceNotes",
        "choreographer1",
        "choreographer2",
        "disabilityStatusId",
        "disabilityStatus",
        "ethnicityId",
        "ethnicity",
        "eligibilityTypeId",
        "firstNamePronunciation",
        "faxPhoneId",
        "faxPhone",
        "foreignFederationId",
        "genderNavigation",
        "guardianEmail",
        "guardianFirstName",
        "guardianLastName",
        "highSchool",
        "homePhoneId",
        "honoraryMember",
        "headCoachId",
        "headCoach",
        "lastNamePronunciation",
        "lastActivity",
        "loginAttemptCount",
        "loginStatus",
        "ltsmembershipNumber",
        "modifiedById",
        "olympicContact",
        "prefixId",
        "prefix",
        "primaryAddressId",
        "primaryEmailId",
        "pendingMembershipTypeChangeCreditMemo",
        "regionId",
        "region",
        "suffixId",
        "securityQuestionId",
        "securityQuestionAnswer",
        "statusNavigation",
        "timestamp",
        "trainingRinkId",
        "trainingRink",
        "veteranStatusId",
        "veteranStatus",
        "workPhoneId",
        "windowsUserName",
      ];
    }
  };

  const onSubmit = async (person: any) => {
    try {
      const response = await axios.put(
        `/api/Person/${person.id}`,
        person,
        await getHeaders()
      );
      console.log(response.data);
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const handleArrowClick = (key: string, direction: "left" | "right") => {
    if (direction === "left") {
      let value = secondary[key];
      setNewPrimary((prev: any) => ({
        ...prev,
        [key]: value,
      }));
      if (value instanceof Object) {
        let keyId = key + "Id";
        if (Object.keys(value).includes(keyId)) {
          setNewPrimary((prev: any) => ({
            ...prev,
            [keyId]: value,
          }));
          setValue(keyId, value);
        }
      } else {
        setValue(key, value);
      }

      // setValue(key, secondary[key]);
    } else if (direction === "right") {
      let value = primary[key];
      setNewPrimary((prev: any) => ({
        ...prev,
        [key]: primary[key],
      }));
      if (value instanceof Object) {
        let keyId = key + "Id";
        if (Object.keys(value).includes(keyId)) {
          setNewPrimary((prev: any) => ({
            ...prev,
            [keyId]: value,
          }));
          setValue(keyId, value);
        }
      } else {
        setValue(key, value);
      }

      // setValue(key, primary[key]);
    }
  };

  const renderCurrentValue = (value: any) => {
    return JSON.stringify(value);
  };

  const handleNewWindowClick = (key: string) => {
    const newKey = key.replace("Id", "");
    const id1 = primary[key];
    const id2 = secondary[key];
    const dataType = newPrimary[newKey].constructor.name.toLowerCase();
    const url = `${window.location.origin}/compare?id1=${id1}&id2=${id2}&type=${dataType}`;
    window.open(url, "_blank");
  };

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setShowOnlyDiff(checked);
  };

  const handleSwapClick = () => {
    setNewPrimary(secondary);
    setPrimary(secondary);
    setSecondary(primary);

    reset(secondary, { keepDirty: true, keepValues: true });
  };

  const handleCopyAllClick = () => {
    const diff = Column(primary, true)
      .filter((p) => p !== "id")
      .filter((p) => primary[p] !== secondary[p]);
    // .sort((a, b) => a[0].localeCompare(b[0]));
    diff.forEach((key) => {
      setNewPrimary((prev: any) => ({
        ...prev,
        [key]: secondary[key],
      }));
    });
  };

  const handleResetAllClick = () => {
    const diff = Column(primary, true)
      .filter((p) => p !== "id")
      .filter((p) => primary[p] !== secondary[p]);
    // .sort((a, b) => a[0].localeCompare(b[0]));
    diff.forEach((key) => {
      setNewPrimary((prev: any) => ({
        ...prev,
        [key]: primary[key],
      }));
    });
  };

  const handleRemoveClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const showExpandButton = (key: string) => {
    if (!key.includes("Id")) {
      return <></>;
    }
    if (primary[key] === null || primary[key] === undefined) {
      return <></>;
    }
    const newKey = key.replace("Id", "");
    if (primary[newKey] === null || primary[newKey] === undefined) {
      return <></>;
    }

    return (
      <IconButton
        aria-label={`Toggle ${key} details`}
        onClick={() => {
          setExpandedRow((prev) => (prev === key ? null : key));
        }}
      >
        {expandedRow === key ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
    );
  };

  const handleShowAllField = () => {
    setShowAll((prev) => !prev);
  }

  const renderNestedTable = (key: string) => {
    const newKey = key.replace("Id", "");
    const nestedPrimary = primary[newKey];
    const nestedSecondary = secondary[newKey];
    if (!primary[newKey]) return <></>;
    const props = listNonClassProperties(nestedPrimary);

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderCell>Property</HeaderCell>
              <HeaderCell>ID {primary["id"]}</HeaderCell>
              <HeaderCell>ID {secondary["id"]}</HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.map((key, index) => (
              <TableRow
                key={`${key}-nested`}
                style={{
                  backgroundColor:
                    JSON.stringify(primary[key]) !==
                    JSON.stringify(secondary[key])
                      ? "#ffeb3b33" // Change the color according to your preference
                      : "inherit",
                }}
              >
                <ValueCell>
                  <Typography>{key}</Typography>
                </ValueCell>
                <ValueCell>
                  <Typography>
                    {renderCurrentValue(nestedPrimary[key])}
                  </Typography>
                </ValueCell>
                <ValueCell>
                  {renderCurrentValue(nestedSecondary[key])}
                </ValueCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderTableBody = () => {
    if (!primary || !secondary) {
      return (
        <>
          {loading ? (
            <TableRow>
              <TableCell colSpan={6} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            <Typography> No data </Typography>
            // Your existing form and table code here
          )}
        </>
      );
    }

    return nonClassProp.map((key, index) => (
      <Fragment key={index}>
        <TableRow
          key={`${key}-left`}
          style={{
            backgroundColor:
              JSON.stringify(primary[key]) !== JSON.stringify(secondary[key])
                ? "#ffeb3b33" // Change the color according to your preference
                : "inherit",
          }}
        >
          <ValueCell>
            <Typography>
              {key} {showExpandButton(key)}
            </Typography>
          </ValueCell>
          <ValueCell>
            <Controller
              control={control}
              name={key}
              render={({ field }) =>
                renderValue(newPrimary[key], (newValue) => {
                  setNewPrimary((prev: any) => ({
                    ...prev,
                    [key]: newValue,
                  }));
                  setValue(field.name, newValue);
                })
              }
            />
          </ValueCell>
          <ValueCell>

            <IconButton
            disabled= {index >= 22}
              onClick={() => handleArrowClick(key, "left")}
              key={`${key}-arrow-left`}
              aria-label={`Copy ${key} from person 2 to person 1`}
            >
              <ArrowCircleLeftIcon />
            </IconButton>
            <IconButton
            disabled= {index >= 22}
              onClick={() => handleArrowClick(key, "right")}
              key={`${key}-arrow-right`}
              aria-label={`Copy ${key} from person 2 to person 1`}
            >
              <RestartAltIcon />
            </IconButton>
          </ValueCell>
          <ValueCell>{renderCurrentValue(secondary[key])}</ValueCell>
        </TableRow>
        {expandedRow === key && (
          <TableRow key={`${key}-right`}>
            <TableCell colSpan={4}>
              <Collapse in={expandedRow === key} timeout="auto" unmountOnExit>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={4}>
                        {renderNestedTable(key)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </Fragment>
    ));
  };

  return (
    primary &&
    secondary && (
      <Box sx={{ my: 3 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Paper>
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
              }}
            >
              <Typography
                sx={{ flex: "1 1 30%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Comparison Table
              </Typography>
              <Tooltip title="Show only different values">
                <Switch size="medium" onChange={handleSwitch} />
              </Tooltip>
              <Box sx={{ flex: "1 1 100%" }} />

              <Typography>|</Typography>

              <Tooltip title={`Save for Id ${primary["id"]}`}>
                <IconButton type="submit" size="large">
                  <SaveIcon />
                </IconButton>
              </Tooltip>
              <Typography>|</Typography>
              <Tooltip title="Swap">
                <IconButton
                  size="large"
                  sx={{ mx: 1 }}
                  onClick={handleSwapClick}
                >
                  <SwapHorizIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Copy All">
                <IconButton
                  size="large"
                  sx={{ mx: 1 }}
                  onClick={handleCopyAllClick}
                >
                  <KeyboardDoubleArrowLeftIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Reset All">
                <IconButton
                  size="large"
                  sx={{ mx: 1 }}
                  onClick={handleResetAllClick}
                >
                  <RestartAltIcon />
                </IconButton>
              </Tooltip>
              <Typography>|</Typography>

              <Tooltip title={`Scan and Merge for ${secondaryId}`}>
                <IconButton size="large" onClick={handleRemoveClick}>
                  <DeleteSweepIcon />
                </IconButton>
              </Tooltip>
              <Typography>|</Typography>
            </Toolbar>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <HeaderCell>Property</HeaderCell>
                    <HeaderCell>ID {primary["id"]}</HeaderCell>
                    <HeaderCell>Actions</HeaderCell>
                    <HeaderCell>ID {secondary["id"]}</HeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    {renderTableBody()}
                </TableBody>
              </Table>
              <Box sx={{m:1}}>
              <Button  fullWidth variant="outlined" onClick={handleShowAllField} >{showAllField ? "Show less fields": "Show all fields"}</Button>
              </Box>

            </TableContainer>
          </Paper>
        </form>
        <DeleteDialog
          primaryId={primary.id}
          secondaryId={secondary.id}
          primaryMemId={primary.membershipNumber}
          secondaryMemId={secondary.membershipNumber}
          primaryEmail={primary.primaryEmail}
          secondaryEmail={secondary.primaryEmail}
          open={dialogOpen}
          handleClose={handleDialogClose}
        />
      </Box>
    )
  );
};

export default ComparedTable;

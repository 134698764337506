import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
  Box,
  Toolbar,
  Button,
  Typography,
  Paper,
  alpha,
} from "@mui/material";
import { Person } from "../models/person";
import axios from "axios";
import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridSelectionModel,
} from "@mui/x-data-grid";
import { getHeaders } from "../utils/authHeaders";

const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 150 },
    { field: "membershipNumber", headerName: "Member #" },
    { field: "lastName", headerName: "Last Name" },
    { field: "firstName", headerName: "First Name" },
    { field: "birthDate", headerName: "Birthday", width: 120 },
    { field: "city", headerName: "City", width: 150 },
    { field: "state", headerName: "State", width: 50 },
    { field: "usFsLastEndDate", headerName: "USFS" },
    { field: "ltsLastEndDate", headerName: "LTS" },
];

interface EnhancedTableToolbarProps {
  numSelected: number;
}

export const PersonDetails: React.FC = () => {
  const { ids } = useParams<{ ids: string }>();

  const [persons, setPersons] = useState<Person[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<GridRowId[]>([]);
  const [compareButtonDisabled, setCompareButtonDisabled] =
    React.useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (!ids) return;
      try {
        if (ids.includes("PER")) {
          const response = await axios.get<Person[]>(
            `/api/Person/list/${encodeURIComponent(ids)}`,
            await getHeaders()
          );
          setPersons(response.data);
        } else {
            if (ids.split(",")[0].length !== 7) {
                return
            }
            const response = await axios.get<Person[]>(
              `/api/Person/listbymember/${encodeURIComponent(ids)}`,
              await getHeaders()
            );
            setPersons(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [ids]);

  const handleCompareButtonClick = () => {
    console.log("Comparing rows:", selectedRows);
    const id1 = selectedRows[0];
    const id2 = selectedRows[1];
    const url = `${window.location.origin}/compare?id1=${id1}&id2=${id2}&type=person`;
    window.open(url, "_blank");
  };
  const isRowSelectable = (params: any) => {
    // Allow row selection if less than 2 rows are selected or the current row is already selected
    return selectedRows.length < 2 || selectedRows.includes(params.id);
  };
  const handleSelectionModelChange = (
    newSelectionModel: GridSelectionModel
  ) => {
    setSelectedRows(newSelectionModel);

    const numberOfRowsSelected = newSelectionModel.length;
    setCompareButtonDisabled(numberOfRowsSelected !== 2);
  };

  const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const { numSelected } = props;

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Persons Table
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleCompareButtonClick}
          disabled={compareButtonDisabled}
          style={{ marginTop: 16 }}
        >
          Compare
        </Button>
      </Toolbar>
    );
  };

  return (
    <Container>
      <h1>Pick Records To Compare</h1>
      <Box sx={{ my: 1 }}>
        <Button></Button>
      </Box>
      <Paper>
        <EnhancedTableToolbar numSelected={selectedRows.length} />
        <DataGrid
          autoHeight
          rows={persons}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={handleSelectionModelChange}
          selectionModel={selectedRows}
          isRowSelectable={isRowSelectable}
        />
      </Paper>
    </Container>
  );

  // return (
  //     <Container>
  //         <Table>
  //             <TableHead>
  //                 <TableRow>
  //                     <TableCell>ID</TableCell>
  //                     <TableCell>Full Name</TableCell>
  //                     <TableCell>Birthday</TableCell>
  //                     <TableCell>Address</TableCell>
  //                 </TableRow>
  //             </TableHead>
  //             <TableBody>
  //                 {persons.map((person) => (
  //                     <TableRow key={person.id}>
  //                         <TableCell>{person.id}</TableCell>
  //                         <TableCell>{person.fullName}</TableCell>
  //                         <TableCell>{person.birthdate ?? ""}</TableCell>
  //                         <TableCell>{person.address}</TableCell>
  //                     </TableRow>
  //                 ))}
  //             </TableBody>
  //         </Table>
  //     </Container>
  // );
};

import React, { useCallback, useEffect, useState } from "react";
import { getHeaders } from "../utils/authHeaders";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  CircularProgress,
  Box,
  Typography,
  Toolbar,
  TextField,
  Divider,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";
import { Person } from "../models/person";

interface DuplicatePersonResult {
  firstName: string;
  middleName: string | null;
  lastName: string;
  fullName: string;
  idListString: string;
  idList: string[];
  membershipNumberListString: string;
  membershipNumberList: string[];
  duplicateCount: number;
  totalDuplicateCount: number;
}

interface DuplicatePersonsTableProps {
  pagesize?: number | undefined;
}

const DuplicatePersonsTable: React.FC<DuplicatePersonsTableProps> = ({
  pagesize = 50,
}) => {
  const [data, setData] = useState<DuplicatePersonResult[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pagesize);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("name");
  const [searchPlaceholder, setSearchPlaceholder] = useState("ie. John Smith");
  const [hasSearched, setHasSearched] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setData([]);
      try {
      const response = await axios.get(
        `/api/person/duplicates?pageNumber=${
          page + 1
        }&pageSize=${rowsPerPage}&search=${encodeURIComponent(search)}`,
        await getHeaders()
      );
      const data = response.data as DuplicatePersonResult[];
      data.forEach((item) => {
        item.fullName = `${item.firstName} ${
          item.middleName ? item.middleName : ""
        } ${item.lastName}`;
      });
      setData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [page, rowsPerPage, search]);

  const handleScanButtonClick = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setHasSearched(true);
    if (searchBy === "name") {
      await fetchData();
    } else if (searchBy === "membership") {
      let ids = search.replace(/\s/g, "").split(",");
      if (ids.length === 1) {
        return;
      }
      if (!ids.every(id => id.length === 7)) {
        return;
      }
      if (ids.length === 2) {
        setFetching(true);
        try {
          const response = await axios.get<Person[]>(
            `/api/Person/listbymember/${encodeURIComponent(search.replace(/\s/g, ""))}`,
            await getHeaders()
          );
          let personIds = response.data.map((person) => person.id);
          const url = `${window.location.origin}/compare?id1=${personIds[0]}&id2=${personIds[1]}&type=person`;
          window.open(url, "_blank");
        } catch (error) {
          console.log(error);
        } finally {
          setFetching(false);
        }
      } else {
        const url = `${window.location.origin}/details/${encodeURIComponent(
          search.replace(/\s/g, "")
        )}`;
        window.open(url, "_blank");
      }
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
    fetchData();
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    fetchData();
  };

  const handleSearchChange = (event: any) => {
    setSearch(event.target.value);
  };

  const handleSearchByChange = (event: SelectChangeEvent) => {
    setSearchBy(event.target.value as string);
    if (event.target.value === "name") {
      setSearchPlaceholder("ie. John Smith");
    } else if (event.target.value === "membership") {
      setSearchPlaceholder("ie. 1234567, 7654321");
    }
  };

  const renderLink = (
    idListString: string,
    idList: string[],
    Children: string | React.ReactNode
  ) => {
    const link =
      idList.length === 2
        ? `/compare?id1=${idList[0]}&id2=${idList[1]}&type=person`
        : `/details/${encodeURIComponent(idListString)}`;
    return (
      <Link to={link} target="_blank" rel="noopener noreferrer">
        {Children}
      </Link>
    );
  };

  return (
    <Paper sx={{ mt: 2 }}>
      <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Duplicate Person Table
        </Typography>
      </Toolbar>
      <Box display="flex" alignItems="center">
        <Box width={150}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Search by</InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={searchBy}
              label="Search by"
              onChange={handleSearchByChange}
            >
              <MenuItem value={"name"}>Name</MenuItem>
              <MenuItem value={"membership"}>Member Number</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box component="form" flexGrow={1} sx={{ m: 1 }} onSubmit={handleScanButtonClick}>
          <TextField
            id="standard-basic"
            // label="Search by name"
            placeholder={searchPlaceholder}
            fullWidth
            size="small"
            value={search}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    type="submit"
                    name="Scan"
                    disabled={loading || fetching}
                  >
                    {searchBy === "name"
                      ? loading
                        ? "Scanning..."
                        : "Scan"
                      : "Go"}
                  </Button>
                </>
              ),
            }}
          />
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Full Name</TableCell>
              <TableCell>IDs</TableCell>
              <TableCell>Membership Numbers</TableCell>
              <TableCell>Duplicate Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <Box display="flex" justifyContent="center">
                    <CircularProgress />
                  </Box>
                </TableCell>
              </TableRow>
            ) : !hasSearched ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <Box display="flex" justifyContent="center" p={3}>
                    <Typography variant="body1">
                      Click "Scan" to find duplicate persons.
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ) : data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <Box display="flex" justifyContent="center" p={3}>
                    <Typography variant="body1">
                      No duplicate persons found for the given search criteria.
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              data.map((row) => (
                <TableRow key={`${row.fullName}`}>
                  <TableCell component="th" scope="row">
                    {renderLink(row.idListString, row.idList, row.fullName)}
                  </TableCell>
                  <TableCell sx={{ maxWidth: 400, wordWrap: "break-word" }}>
                    {row.idList.join(", ")}
                  </TableCell>
                  <TableCell sx={{ maxWidth: 400, wordWrap: "break-word" }}>
                    {row.membershipNumberList.join(", ")}
                  </TableCell>
                  <TableCell>{row.duplicateCount}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data.length ? data[0].totalDuplicateCount : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default DuplicatePersonsTable;

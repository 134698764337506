import { Routes, Route, useNavigate } from "react-router-dom";
// Material-UI imports
import Grid from "@mui/material/Grid";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { CustomNavigationClient } from "./utils/NavigationClient";

// Sample app imports
import { PageLayout } from "./components/PageLayout";
import { Compare } from "./pages/compare";
import { Protected } from "./pages/Protected";
import { Home } from "./pages/Home";
import { PersonDetails } from "./pages/PersonDetails";
type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  // const history = useNavigate();
  // const navigationClient = new CustomNavigationClient(history);
  // pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <PageLayout>
          <Pages />
      </PageLayout>
    </MsalProvider>
  );
}

function Pages() {
  return (
    <Routes>
      <Route path="/compare" element={<Compare />}></Route>
      <Route path="/protected" element={<Protected />}></Route>
      <Route path="/details/:ids" element={<PersonDetails />} />
      <Route path="/" element={<Home />}></Route>
    </Routes>
  );
}

export default App;

import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Box, Snackbar, Typography } from "@mui/material";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import ComparedTable from "../components/ComparedTable";


export function Compare() {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [searchParams] = useSearchParams();
    const id1 = searchParams.get("id1");
    const id2 = searchParams.get("id2");
    const type = searchParams.get("type");

    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    return (
        <Box>
            <AuthenticatedTemplate>
                {id1 && id2 && type && (
                    <ComparedTable primaryId={id1} secondaryId={id2} dataType={type} />
                )}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Typography variant="h6" align="center">
                    Please sign-in.
                </Typography>
            </UnauthenticatedTemplate>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                message={message}
                key={"top,center"}
            />
        </Box>
    );
}

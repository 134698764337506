import { msalInstance } from "../index";
import { loginRequest } from "../authConfig";

export async function getHeaders() {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const authResponse = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });

    const bearer = `Bearer ${authResponse.accessToken}`;
    return { headers: { "Authorization": bearer } }
}

import { Configuration, LogLevel, PopupRequest } from "@azure/msal-browser";

export const getHostName = () => document.location.hostname.toLowerCase()
export const isLocalHost = () => getHostName() === 'localhost'
export const isUatHost = () => getHostName() === 'dc-test2.usfigureskating.org'

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
    auth: {
        clientId: "604e0abf-03fa-4edf-816f-1e22b45b540e",
        authority: "https://login.microsoftonline.com/964257e8-04cb-48ec-ab7c-63de46e7cfd4",
        redirectUri: isLocalHost() ? 'https://localhost:44417' : 'https://dc-test2.usfigureskating.org',
        postLogoutRedirectUri: "/",
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};


/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const silentRequest = {
    scopes: ["openid", "profile"],
    loginHint: "example@domain.net"
};

// Scopes you add here will be prompted for consent during login
export const loginRequest: PopupRequest = {
    scopes: [
        'openid',
        'offline_access',
        "api://9418030c-c310-4d95-bd97-62dd58eb7d27/api.scope"
    ],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me"
};

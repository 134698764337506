import * as React from "react";
import { useState, useEffect } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import axios from "axios";
import { set } from "react-hook-form";
import DataCleanseLog from "../models/dataCleanseLog";
import { useAccount, useMsal } from "@azure/msal-react";
import { getHeaders } from "../utils/authHeaders";

interface SearchResult {
    schemaName: string;
    tableName: string;
    columnName: string;
    foundValue: string;
    dataType: string;
}

interface DeleteDialogProps {
    primaryId: string;
    primaryMemId: number;
    primaryEmail: string;
    secondaryId: string;
    secondaryMemId: number;
    secondaryEmail: string;
    open: boolean;
    handleClose: () => void;
}

export const DeleteDialog: React.FC<DeleteDialogProps> = ({
    primaryId,
    secondaryId,
    primaryMemId,
    secondaryMemId,
    primaryEmail,
    secondaryEmail,
    open,
    handleClose,
}) => {
    const { accounts } = useMsal();
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [hasData, setHasData] = useState<boolean>(false);
    const [updated, setUpdated] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [elapsedTime, setElapsedTime] = useState<number>(0);
    const [action, setAction] = useState<string>("Please Scan to find the fields that should be merged.");
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");
    const [scanCompleted, setScanCompleted] = useState<boolean>(false);

    useEffect(() => {
        if (account && account.name) {
            setName(account.name.split(" ")[0]);
        } else {
            setName("");
        }
    }, [account]);
    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        if (loading) {
            setElapsedTime(0);
            intervalId = setInterval(() => {
                setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [loading]);

    const handleScanClick = async () => {
        setLoading(true);
        setScanCompleted(false);
        setAction(`Scan for ${secondaryId} in preparation to merge with ${primaryId}`);
        setMessage(`Scanning for ${secondaryId}..., it'll take a while.`);
        try {
            const response = await axios.get(
                `api/Person/search/${secondaryId}`,
                await getHeaders()
            );
            setSearchResults(response.data);
            setHasData(response.data.length > 0);
            setMessage(`Found ${response.data.length} results`);
        } catch (error) {
            setHasData(false);
            setMessage(`Error scanning for ${secondaryId}.`);
        } finally {
            setLoading(false);
            setScanCompleted(true);
        }
    };

    const handleReplaceClick = async () => {
        setLoading(true);
        setAction(`Replace for ${secondaryId} with ${primaryId}`);
        setMessage(`Replacing ${secondaryId} with ${primaryId}...`);
        try {
            await axios.post("api/Person/update-values",
                {
                    searchResults: searchResults,
                    newValue: primaryId,
                },
                await getHeaders());
            let dataCleanseLog: DataCleanseLog = {
                priorPersonId: secondaryId,
                newPersonId: primaryId,
                priorMembershipNumber: secondaryMemId,
                newMembershipNumber: primaryMemId,
                priorEmail: secondaryEmail,
                newEmail: primaryEmail,
                modifiedBy: name,
            }
            // debugger;
            // await axios.post("api/dataCleanseLog", dataCleanseLog)

            setUpdated(true);
            setHasData(false);
            setMessage(`Updated ${searchResults.length} values successfully`);
        } catch (error) {
            debugger;
            setUpdated(false);
            setMessage(`Error updating values.`);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteClick = async () => {
        setLoading(true);
        setAction(`Delete for ${secondaryId}`);
        setMessage(`Deleting ${secondaryId}...`);
        try {
            await axios.delete(`api/Person/${secondaryId}`, await getHeaders());
            setUpdated(false);
            setMessage(`Deleted ${secondaryId} successfully`);
        } catch (error) {
            setUpdated(false);
            setMessage(`Error deleting ${secondaryId}.`);
        } finally {
            setLoading(false);
        }
    };

    const handleDialogClose = (
        event: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "backdropClick") {
            return;
        }
        setAction("Please Scan to find the fields that should be merged.");
        setSearchResults([]);
        setLoading(false);
        setHasData(false);
        setUpdated(false);
        setScanCompleted(false);
        setMessage("");
        handleClose();
    };

    const getTimeString = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}m ${remainingSeconds}s`;
    }

    return (
        <Dialog open={open} onClose={handleDialogClose} maxWidth='lg'>
            <DialogTitle>
                {action}
            </DialogTitle>
            <DialogContent>
                {message != "" && !loading && (
                    <DialogContentText>{message} in {getTimeString(elapsedTime)}.</DialogContentText>
                )}
                <Box sx={{ width: "100%", mt: 3 }}>
                    {loading && (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <LinearProgress sx={{ flexGrow: 1 }} />
                            <Box sx={{ ml: 2 }}>{getTimeString(elapsedTime)}</Box>
                        </Box>
                    )}
                </Box>
                <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Table Name</TableCell>
                                <TableCell>Column Name</TableCell>
                                <TableCell>Found Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {searchResults.map((result, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        {result.schemaName}.{result.tableName}
                                    </TableCell>
                                    <TableCell>{result.columnName}</TableCell>
                                    <TableCell>{result.foundValue}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading || hasData} onClick={handleScanClick}>
                    Scan
                </Button>
                <Button disabled={!hasData || loading} onClick={handleReplaceClick}>
                    Merge
                </Button>
                <Button disabled={!scanCompleted || hasData || loading} onClick={handleDeleteClick}>
                    Remove unwanted duplicates
                </Button>
                <Button onClick={handleDialogClose}>Done</Button>
            </DialogActions>
        </Dialog>
    );
};

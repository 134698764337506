import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import ScheduleIcon from "@mui/icons-material/Schedule";
import MailIcon from "@mui/icons-material/Mail";
import LockOpenIcon from "@mui/icons-material/LockOpen";

export const ProtectedData = ({ responseData }: any) => {
  return (
    <List className="protectedData">
      <NameListItem name={responseData.account.name} />
      <MailListItem mail={responseData.account.username} />
      <AccessTokenExpiresListItem expiresOn={responseData.expiresOn} />
      <ScopesListItem scopes={responseData.scopes} />
    </List>
  );
};

const NameListItem = ({ name }: any) => (
  <ListItem>
    <ListItemAvatar>
      <Avatar>
        <PersonIcon />
      </Avatar>
    </ListItemAvatar>
    <ListItemText primary="Name" secondary={name} />
  </ListItem>
);

const AccessTokenExpiresListItem = ({ expiresOn }: any) => (
  <ListItem>
    <ListItemAvatar>
      <Avatar>
        <ScheduleIcon />
      </Avatar>
    </ListItemAvatar>
    <ListItemText
      primary="Access Token Expires At"
      secondary={expiresOn.toString()}
    />
  </ListItem>
);

const MailListItem: React.FC<{ mail: string }> = ({ mail }) => (
  <ListItem>
    <ListItemAvatar>
      <Avatar>
        <MailIcon />
      </Avatar>
    </ListItemAvatar>
    <ListItemText primary="Username" secondary={mail} />
  </ListItem>
);

const ScopesListItem: React.FC<{ scopes: any }> = ({ scopes }) => (
  <ListItem>
    <ListItemAvatar>
      <Avatar>
        <LockOpenIcon />
      </Avatar>
    </ListItemAvatar>
    <List>
      {scopes.map((scope: any, index: number) =>
        index === 0 ? (
          <ListItemText primary="Scopes" secondary={scope} key={scope} />
        ) : (
          <ListItemText secondary={scope} />
        )
      )}
    </List>
  </ListItem>
);

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import WelcomeName from "./WelcomeName";
import SignInSignOutButton from "./SignInSignOutButton";
import { Link as RouterLink, NavLink } from "react-router-dom";
import logo from "../usfslogo.svg";
import { Box } from "@mui/material";

const NavBar = () => {
    return (
        <div>
            <AppBar
                position="static"
                sx={{ backgroundColor: "white", color: "black" }}
            >
                <Toolbar>
                    {/* <Box sx={{ width: "100px", height: "75px" }}>
            <RouterLink to="/">
              <img src={logo} />
            </RouterLink>
          </Box> */}

                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1 }}
                    >
                        <RouterLink to="/" style={{textDecoration: "none", color:"black"}}>
                            USFS Membership Database Cleansing
                        </RouterLink>
                    </Typography>

                    <WelcomeName />
                    <SignInSignOutButton />
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default NavBar;

import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Box, Snackbar, Typography, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import FullNameGroups from "../components/DuplicatePersonsTable";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

type DatabaseStatus = {
    connectionString: string;
    canConnect: boolean;
    errorMessage: string | null;
};

export function Home() {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [databaseStatus, setDatabaseStatus] = useState<DatabaseStatus | null>(null);
    const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //     fetchDatabaseStatus();
    // }, []);

    const fetchDatabaseStatus = async () => {
        try {
            const response = await fetch('/api/DatabaseStatus');
            if (!response.ok) {
                throw new Error('Failed to fetch database status');
            }
            const data: DatabaseStatus = await response.json();
            setDatabaseStatus(data);
        } catch (error) {
            console.error('Error fetching database status:', error);
            setMessage('Failed to fetch database status');
            setOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    return (
        <Box>
            <AuthenticatedTemplate>
                {/* {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                        <CircularProgress />
                    </Box>
                ) : databaseStatus ? (
                    <Box mb={2}>
                        <Typography variant="h6">Database Status</Typography>
                        <Box display="flex" alignItems="center">
                            {databaseStatus.canConnect ? (
                                <CheckCircleIcon color="success" />
                            ) : (
                                <ErrorIcon color="error" />
                            )}
                            <Typography ml={1}>
                                {databaseStatus.canConnect ? 'Connected' : 'Disconnected'}
                            </Typography>
                        </Box>
                        <Typography>
                            Connection String: {databaseStatus.connectionString}
                        </Typography>
                        {databaseStatus.errorMessage && (
                            <Typography color="error">
                                Error: {databaseStatus.errorMessage}
                            </Typography>
                        )}
                    </Box>
                ) : null} */}
                <FullNameGroups />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Typography variant="h6" align="center">
                    Please sign-in.
                </Typography>
            </UnauthenticatedTemplate>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                message={message}
                key={"top,center"}
            />
        </Box>
    );
}

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import NavBar from "./NavBar";

export const PageLayout: React.FC<{children: any}> = (props) => {
    return (
        <>
            <NavBar />
            <Container >
                {props.children}
            </Container>
        </>
    );
};